@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,500;0,600;0,700;1,400&display=swap');

:root {
  --beige: #EFE9E1;
  --gold: #C9A359;
  --lightGold: #C9A359;
  --blue: #151B37;
}

html, body, div, nav, span, header, p, h1, h2, h3, ul, ol, li {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Jost', sans-serif;
}

html, body {
  scroll-behavior: smooth;
}

h1, h2, h3 {
  font-family: 'Gelasio', serif;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 2rem 3rem;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
}

ul.menu {
  list-style: none;
  display: flex;
  grid-gap: 2rem;
  color: var(--beige);
  text-transform: uppercase;
  font-weight: bold;
}

ul.menu li {
  font-size: 1rem;
}

ul.menu li a {
  color: white;
  text-decoration: none;
}

.hero_image {
  height: 37.5rem;
  background-image: url("./media/aryanoir_hero.webp");
  display: flex;
  justify-content: center;
  align-items: center; 
}

.light_bg {
  background-color: var(--beige);
  background-image: url("./media/mask_bg.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.light_bg.primary {
  padding: 8rem 0;
}

.light_bg.primary .light_bg_inside {
  display: flex;
  margin-left: 16rem;
  flex-direction: column;
}

.light_bg.primary .light_bg_inside p {
  font-family: 'Gelasio', serif;
  font-size: 1.75rem;
  line-height: 1.25;
  margin-top: 1rem;
}

.light_bg_inside {
  max-width: 48rem;
}

.light_bg h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.1;
}

.light_bg p {
  font-size: 1.625rem;
}

.light_bg .link_group {
  margin-top: 3rem;
  display: flex;
  grid-gap: 5rem;
}

.light_bg a {
  color: var(--gold);
  text-transform: uppercase;
  font-size: 1.37rem;
}

.dark_bg {
  background-image: url("./media/aryanoir_photo.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark_bg_inside, .light_bg_inside {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dark_bg_inside .title_element {
  justify-self: flex-end;
  align-self: flex-start;
}

.dark_bg_inside .title_element.right_element, .light_bg_inside .title_element.right_element {
  grid-column: -2 / -1;
  justify-self: flex-start;
  align-self: flex-end;
}

.title_element h2, .main_title, .italic_title {
  font-size: 5.625rem;
  font-weight: 700;
  color: var(--beige);
  text-transform: uppercase;
  text-align: end;
  line-height: 5.6rem;
}

.title_element h2:first-of-type, .italic_title {
  font-style: italic;
  font-weight: 500;
  font-size: 3.75rem;
  color: var(--beige);
  text-transform: none;
}

.dark_bg_inside .par_element {
  grid-row: -2 / -1;
  grid-column: -2 / -1;
  color: var(--beige);
  font-size: 1.375rem;
  max-width: 26rem;
  font-weight: 500;
}

.dark_bg_inside .par_element p div {
  margin-bottom: 1rem;
}

.par_element.block_element {
  max-width: 40rem;
}

.gold {
  background: linear-gradient(92.28deg, #9E7D3D 2.05%, #DEB971 19.9%, #F0D399 30.96%, #DAB46B 41.16%, #9E7D3D 56.46%, #A88645 56.46%, #B6934F 56.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /*text-fill-color: transparent;*/
}

.right_align {
  text-align: start;
}

.right_align {
  text-align: end;
}

.hamb_menu {
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
}

.hamb_menu span {
  background: white;
  border-radius: 0.5rem;
  height: 3px;
  width: 100%;
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;
}

.expertise_title {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.expertise_title h2 {
  text-align: start;
}

.expertise_title h2:first-of-type {
  color: var(--blue);
}

.par_array {
  margin-bottom: 3rem;
}

.par_array h3 {
  color: var(--gold);
  font-style: italic;
  font-weight: 500;
  font-size: 3.75rem;
}

.par_array p {
  font-size: 1.375rem;
  font-weight: 400;
}

.light_bg .title_element h2.dark_title {
  color: var(--blue);
  text-align: start;
}

.contact {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  text-align: end;
  margin-bottom: 2rem;
}

.contact p {
  font-size: 1.375rem;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--blue);
  padding: 2rem 3rem;
}

.card_element {
  height: 375px;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card_frame {
  max-width: 1140px;
  margin: 0 auto 5rem;
}

.card_title {
  color: var(--gold);
  font-size: 4rem;
  line-height: 1.05;
  font-style: italic;
  font-weight: 500;
}

.mobilemenu {
  padding: 1.5rem;
  inset: 0;
  top: 6.25rem;
  list-style: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.25rem;
  background-color: var(--blue);
}

.mobilemenu li {
  color: white;
  margin-bottom: 1.25rem;
  flex-direction: column;
}

.mobilemenu li a {
  color: white;
  text-decoration: none;
}

.left_align {
  text-align: end;
}


/* mobile menu */

label{
  display:flex;
  flex-direction:column;
  width:45px;
  cursor:pointer;
}

label span{
  background: #fff;
  border-radius:10px;
  height: 4px;
  margin: 7px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}

span:nth-of-type(1){
  width:50%;
  
}

span:nth-of-type(2){
  width:100%;
} 

span:nth-of-type(3){
  width:75%;

} 

input[type="checkbox"]{
  display:none;
}


input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(9px,4px)
}


input[type="checkbox"]:checked ~ span:nth-of-type(2){
  
  transform-origin:top;
  transform:rotatez(-45deg)
}


input[type="checkbox"]:checked ~ span:nth-of-type(3){
  
  transform-origin:bottom;
  width:50%;
  transform: translate(19px,-11px) rotatez(45deg);

}

.title_element h2.reversed {
  font-size: 5.625rem;
  font-weight: 700;
  color: var(--beige);
  text-transform: uppercase;
  text-align: end;
  font-style: normal;
}

.title_element h2.reversed_italic {
  font-style: italic;
  font-weight: 500;
  font-size: 3.75rem;
  text-transform: none;
}

@media screen and (max-width: 1020px) {

  nav {
    padding: 1.5rem;
  }

  .light_bg.primary .light_bg_inside {
    margin-left: 0;
    padding: 0 1.5rem;
  }

  .light_bg .link_group {
    flex-direction: column;
    grid-gap: 2rem;
  }

  .dark_bg_inside, .light_bg_inside {
    grid-template-columns: 1fr;
  }

  .dark_bg_inside .par_element {
    grid-row: auto;
    margin-top: 0;
  }

  .cards {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .title_element h2, .main_title {
    font-size: 3.75rem;
    line-height: 4rem;
  }

  .title_element h2:first-of-type, .italic_title, .title_element h2.reversed_italic {
    line-height: 2.5rem;
    font-size: 2.5rem;
  }

  .title_element h2.reversed {
    font-size: 3.75rem;
    line-height: 4rem;
  }

  .dark_bg_inside, .light_bg_inside {
    padding: 1.5rem;
  }
  
  .expertise_title {
    justify-content: flex-end;
  }

  .expertise_title h2 {
    text-align: end;
  }

  .par_array h3 {
    font-size: 3.125rem;
  }

  .light_bg .title_element h2.dark_title {
    text-align: end;   
  }

  .right_align {
    text-align: end;
  }

  .left_align {
    text-align: start;
  }

  .contact {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50px 1fr;
    margin-left: 1.5rem;
  }

  .contact svg {
    order: 1;
  }

  .contact p {
    font-size: 1.15rem;
    order: 2;
    text-align: start;
  }

  footer {
    flex-direction: column;
    gap: 3rem;
  }

  header {
    position: relative;
  }

  .card_frame {
    max-width: 100%;
  }

  .card_title {
    font-size: 3.25rem;
  }

  .light_bg h1 {
    font-size: 3rem;
  }

  .card_element {
    height: 400px;
  }

  .light_bg_inside .title_element.right_element {
    justify-self: flex-end;
  }
}
